import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { navActions } from '../store/nav'

const Logo = () => {
  const dispatch =  useDispatch()
	const onClickHandler = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    // dispatch(navActions.toggle())
	}

	return <LogoStyles to="/homepage" onClick={onClickHandler}>Life</LogoStyles>
}

export default Logo

const LogoStyles = styled(Link)`
	font-size: 1.75rem;
	letter-spacing: 2px;
	color: ${({theme}) => theme.primaryLight};
	text-transform: uppercase;
	font-weight: 300;
`
