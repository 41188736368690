import { Link } from 'react-router-dom'
import styled from 'styled-components';

const IconStyles = styled(Link)`
	padding: 1rem;
	font-size: 1.25rem;
	color: ${({theme}) => theme.primaryLight};
	text-transform: uppercase;
`

const LogoIcon = (image) => {
	const onClickHandler = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
	}

	return (
		<IconStyles to="/homepage" onClick={onClickHandler}>
			<img src="../assets/leaves.png" alt="" />
		</IconStyles>
		)
}

export default LogoIcon
