import { keyframes } from 'styled-components'

// Burger animation
export const animationOne = keyframes`
	0%, 50% { transform: rotate(0deg); }
	75% { transform: rotate(360deg); }
	100% { transform: rotate(315deg); }
`

export const animationOneReverse = keyframes`
	0% { transform: rotate(315deg); }
	25% { transform: rotate(360deg); }
	50%, 100% { transform: rotate(0deg); }
`

export const animationTwo = keyframes`
	0%, 50% { transform: rotate(0deg); }
	75% { transform: rotate(450deg); }
	100% { transform: rotate(405deg); }
`

export const animationTwoReverse = keyframes`
	0% { transform: rotate(405deg); }
	25% { transform: rotate(450deg); }
	50%, 100% { transform: rotate(0deg); }
`

export const animationBurgerTop = keyframes`
	0% { transform: translateY(0px); opacity: 1; }
	50% { transform: translateY(12px); opacity: 1; }
	51%, 100% { transform: translateY(12px); opacity: 0; }
`

export const animationBurgerTopReverse = keyframes`
	0%, 50% { transform: translateY(12px); opacity: 0; }
	51% { transform: translateY(12px); opacity: 1; }
	100% { transform: translateY(0px); opacity: 1; }
`

export const animationBurgerBottom = keyframes `
	0% { transform: translateY(0px); opacity: 1; }
	50% { transform: translateY(-12px); opacity: 1; }
	51%, 100% { transform: translateY(-12px); opacity: 0; }
`

export const animationBurgerBottomReverse = keyframes`
	0%, 50% { transform: translateY(-12px); opacity: 0; }
	51% { transform: translateY(-12px); opacity: 1; }
	100% { transform: translateY(0px); opacity: 1; }
`

export const menuAnimation = keyframes`
  0% {clip-path: inset(0 0 100% 0); height: 0;}
  100% {clip-path: inset(0 0 0 0); height: 100vh;}
`
